import { EXPERIENCE_PUBLIC_URL } from './constants'
import { isBrowser } from './utils'

export const putCreditCard = async params => {
  const creditCardInfo = params.creditCardInfo
  const userId = params.userId

  const fullYear = '20' + creditCardInfo.expiryDate.split('/')[1].trim()

  /* eslint-disable-next-line no-undef */
  const response = await fetch(EXPERIENCE_PUBLIC_URL + 'mvno/' + userId + '/creditCard', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      cardNumber: creditCardInfo.cardNumber,
      expiryDate: {
        month: creditCardInfo.expiryDate.split('/')[0].trim(),
        year: fullYear,
      },
      cvc: creditCardInfo.cvc,
      redirectUrl: isBrowser() ? (window.location.href + '?userId=' + userId) : ('https://sambandid.is/payment/?userId=' + userId),
      source: 'sambandid.is',
    }),
  })

  if (response.ok) {
    return response.json()
  }

  throw new Error('Error updating creditcard')
}

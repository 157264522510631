import { fadeIn } from 'react-animations'
import styled, { keyframes } from 'styled-components'

const fadeInAnimation = keyframes`${fadeIn}`

const LabelText = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3px;
  letter-spacing: 0.2px;
  line-height: 16px;
  height: 16px;
  color: white;
`

const HeaderText = styled.div`
  font-weight: 300;
  font-size: 40px;
  color: #FFFFFF;
  height: 100px;
`

const ErrorTextBig = styled.div`
  font-weight: 300;
  font-size: 36px;
  color: #FFFFFF;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.27px;
  line-height: 18px;
`

const Input = styled.input`
  display: flex;
  font-weight: 200;
  font-size: 18px;
  height: 40px;
  line-height: 24px;
  padding-left: 15px;
  border: 1px solid black;
  border-radius: 4px;
  max-width: ${props => `${props.maxWidth}%` ?? '60%'};
  flex-grow: ${props => props.flexGrow ?? '2'};
  margin-right: ${props => props.marginRight ?? '10px'};
  width: ${props => props.width ?? 'initial'};
  -webkit-box-sizing: ${props => props.boxSizing ?? 'initial'}; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: ${props => props.boxSizing ?? 'initial'};    /* Firefox, other Gecko */
  box-sizing: ${props => props.boxSizing ?? 'initial'}; 
  :focus {
    outline: 0;
  }
`

const InputErrorWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  height: 0px;
  position: relative;
  top: 6px;
  width: 100%;
`

const InputErrorContainer = styled(InputErrorWrap)`
  margin-top: 0;
  animation: 1s ${fadeInAnimation};
`

const ErrorIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`

const CreditCardFormContainer = styled.div`
  width: 428px;
  height: 100vh;
  background-color: black;
  color: black;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    height: 80vh;
  }
`

const Container = styled.div`
  width: ${props => props.width ? props.width : 'initial'};
  height: ${props => props.height ? props.height : 'initial'};
  margin: ${props => props.margin ? props.margin : 'initial'};
  text-align: ${props => props.textAlign ? props.textAlign : 'initial'};
  display: ${props => props.display ? props.display : 'block'};
  justify-content: center;
`

const Form = styled.form`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
`

const FormContainer = styled.div`
  width: 428px;
  background: 'transparent';
  box-shadow: none;
  @media (max-width: 468px) {
    width: 100%;
  }
`

const RowItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`

const RowItem = styled.div`
    width: ${props => (props.width ? props.width : '100%')};
    margin-top: ${props => (props.marginTop ? props.marginTop : '5px')};
`

const CreditCardInputContainer = styled.div`
    display: flex;
    border-radius: 5px;
    border: 1px solid #C1C7D0;
    height: 40px;
    width: 100%;
    align-items: center;
    background-color: #FFFFFF;
`

const CreditCardInput = styled.input`
    background-color: inherit;
    width: 100%;
    border: unset;
    margin: unset;
    padding: unset;
    outline: unset;
    padding-left: 0.6em;
    line-height: 24px;
    font-weight: 200;
    font-size: 18px;
`

const CreditCardSvg = styled.svg`
    padding-left: 10px;
`

const HiddenItem = styled.div`
  display: none;
`

const StyledButton = styled.button`
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#FFFFFF')};
    color: ${props => (props.color ? props.color : '#000000')};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : '700')};
    font-size: 20px;
    border: ${props => (props.border ? props.border : 'none')};
    padding: ${props => (props.padding ? props.padding : '10px')};
    border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};
    position: ${props => (props.position ? props.position : 'relative')};
    margin-top: 25px;
    line-height: 16px;
    cursor: pointer;
    width: ${props => (props.width ? props.width : '100%')};
    height: ${props => (props.height ? props.height : '38px')};

    &:disabled {
        cursor: default;
        background-color: dimgray;
    }
`

export {
  CreditCardFormContainer,
  Container,
  Form,
  FormContainer,
  RowItems,
  RowItem,
  HiddenItem,
  CreditCardInput,
  CreditCardInputContainer,
  CreditCardSvg,
  LabelText,
  HeaderText,
  ErrorText,
  Input,
  InputErrorContainer,
  InputErrorWrap,
  ErrorIcon,
  StyledButton,
  ErrorTextBig
}

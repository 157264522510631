import { EXPERIENCE_PUBLIC_URL } from './constants'

export const pollCreditCard = async ({ queryKey }) => {
  const [_, { transactionId, userId }] = queryKey

  /* eslint-disable-next-line no-undef */
  const response = await fetch(EXPERIENCE_PUBLIC_URL + 'mvno/' + userId + '/creditCard/' + transactionId, {
    method: 'GET'
  })

  if (response.ok) {
    return response.json()
  }

  throw new Error('Error polling status of creditcard update')
}

import styled from 'styled-components'

export const StyledCircle = styled.circle`
  stroke: ${props => props.color};
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
`

export const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: 0 0 0 0;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

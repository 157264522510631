import React from 'react'
import Error from '../../../images/icon-failed-red.svg'
import { ErrorIcon, InputErrorContainer, InputErrorWrap, ErrorText } from './Styles'

export default function InputError ({ message }) {
  return (
    <InputErrorWrap>
      {message && (
        <InputErrorContainer>
          <ErrorIcon src={Error} />
          <ErrorText>{message}</ErrorText>
        </InputErrorContainer>
      )}
    </InputErrorWrap>
  )
}

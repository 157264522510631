import React, { useState } from 'react'
import InputError from './InputError'
import {
  CreditCardFormContainer,
  RowItems,
  RowItem,
  CreditCardInput,
  CreditCardInputContainer,
  CreditCardSvg,
  Form,
  LabelText,
  Input,
  StyledButton,
  HeaderText
} from './Styles'
import { usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import { ERROR_MESSAGES } from '../constants'

export default function CreditCardForm (props) {
  const [cardValid, setCardValid] = useState(false)
  const [cardInformation, setCardInformation] = useState({
    cardNumber: '',
    expiryDate: '',
    cvc: '',
  })

  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
    onChange: e => handleOnChange(e),
    onError: (error, erroredInputs) => handleOnError(error, erroredInputs)
  })

  const handleOnChange = e => {
    setCardInformation({
      ...cardInformation,
      [e.target.name]: e.target.value,
    })
  }

  const callbackCardInfoToParent = () => {
    props.callback(cardInformation)
  }

  /* eslint-disable-next-line handle-callback-err */
  const handleOnError = (error, erroredInputs) => {
    setCardValid(!isErrorOnCard(erroredInputs))
  }

  const isErrorOnCard = erroredInputs => {
    return erroredInputs.cardNumber || erroredInputs.expiryDate || erroredInputs.cvc
  }

  return (
    <CreditCardFormContainer>
      <Form>
        <HeaderText>Uppfæra kort</HeaderText>
        <RowItem marginTop={'20px'}>
          <LabelText>Kreditkortanúmer</LabelText>
          <CreditCardInputContainer>
            <CreditCardSvg {...getCardImageProps({ images })} />
            <CreditCardInput
              {...getCardNumberProps()}
              placeholder={'**** **** **** ****'}
              maxLength={19}
            />
          </CreditCardInputContainer>
        </RowItem>
        <RowItems>
          <RowItem width={'45%'}>
            <LabelText>Gildistími</LabelText>
            <Input
              {...getExpiryDateProps()}
              maxWidth={'100'}
              maxLength={7}
              placeholder='MM/ÁÁ'
              width={'100%'}
              boxSizing={'border-box'}
            />
          </RowItem>
          <RowItem width={'45%'}>
            <LabelText>CVC</LabelText>
            <Input
              {...getCVCProps()}
              maxWidth={'100'}
              width={'100%'}
              boxSizing={'border-box'}
            />
          </RowItem>
        </RowItems>
        {meta.isTouched && meta.error && <InputError message={meta.error}/>}
        <StyledButton type='button' disabled={!cardValid} onClick={() => callbackCardInfoToParent()}>Staðfesta</StyledButton>
      </Form>
    </CreditCardFormContainer>
  )
}

const ERROR_MESSAGES = {
  emptyCardNumber: 'Vantar kortanúmer',
  invalidCardNumber: 'Kortanúmer er ógilt',
  emptyExpiryDate: 'Vantar gildistíma',
  monthOutOfRange: 'Mánuður verður að vera á milli 01 og 12',
  yearOutOfRange: 'Gildisár getur ekki verið í fortíðinni',
  dateOutOfRange: 'Gildistími getur ekki verið í fortíðinni',
  invalidExpiryDate: 'Gildistími ógildur',
  emptyCVC: 'Vantar CVC númer',
  invalidCVC: 'CVC númer er ógilt',
}

const MAX_CREDITCARD_POLL_COUNT = 60
const CREDITCARD_POLL_INTERVAL = 1000

const EXPERIENCE_PUBLIC_URL = process.env.NODE_ENV === 'production'
  ? 'https://api.syn.is/experience/public/'
  : 'https://syn-staging-experience-public.ir-e1.cloudhub.io/api/'

export { ERROR_MESSAGES, EXPERIENCE_PUBLIC_URL, MAX_CREDITCARD_POLL_COUNT, CREDITCARD_POLL_INTERVAL }

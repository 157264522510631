import React from 'react'
import { StyledCircle, StyledSpinner, Center } from './Styles'

function Spinner ({ color, size = 100 }) {
  if (!color) {
    color = 'white'
  }

  return (
    <Center>
      <StyledSpinner viewBox="0 0 50 50" width={size} height={size}>
        <StyledCircle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="3"
          color={color}
        />
      </StyledSpinner>
    </Center>
  )
}

export default Spinner

import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import PaymentContainer from './PaymentContainer/PaymentContainer'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const payment = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <PaymentContainer />
    </QueryClientProvider>
  )
}

export default payment

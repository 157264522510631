import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import PageWrapper from '../../../shared/PageWrapper/PageWrapper'
import CreditCardForm from '../CreditCardForm/CreditCardForm'
import { Container, ErrorTextBig, StyledButton } from '../CreditCardForm/Styles'
import Spinner from '../Spinner/Spinner'
import { Link } from 'gatsby'
import Text from '../../../shared/Text/Text'
import { pollCreditCard } from '../queries'
import { putCreditCard } from '../mutations'
import { MAX_CREDITCARD_POLL_COUNT, CREDITCARD_POLL_INTERVAL } from '../constants'
import { isBrowser } from '../utils'
import '../../../styles/index.scss'

const PaymentContainer = () => {
  const [userId, setUserId] = useState(null)
  const [transactionId, setTransactionId] = useState(false)
  const [cardRegistered, setCardRegistered] = useState(false)
  const [isPolling, setIsPolling] = useState(false)
  const [pollCount, setPollCount] = useState(0)
  const [error, setError] = useState(null)

  const queryParams = isBrowser() ? new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  }) : null

  useEffect(() => {
    if (queryParams.userId) {
      setUserId(queryParams.userId)
    } else {
      handleSetError('Notandi fannst ekki')
    }
    if (queryParams.transactionId) {
      setTransactionId(queryParams.transactionId)
    }
    if (isBrowser()) {
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  }, [])

  useEffect(() => {
    setIsPolling(transactionId && !cardRegistered && !error)
  }, [transactionId, cardRegistered, error])

  useQuery(['pollCreditCard', { transactionId, userId }], pollCreditCard, {
    onSuccess: response => {
      switch (response.status) {
        case 'SUCCESS':
          setPollCount(pollCount + 1)
          setTransactionId(false)
          setUserId(null)
          setCardRegistered(true)
          break
        case 'PROCESSING':
          setPollCount(pollCount + 1)
          break
        case 'FAILURE':
          handleSetError('Mistókst að skrá kort')
          break
        default:
          handleSetError('Mistókst að skrá kort')
          break
      }
    },
    onError: () => {
      handleSetError('Mistókst að sækja stöðu á kortaskráningu')
    },
    enabled: (
      !!transactionId &&
      isPolling &&
      pollCount < MAX_CREDITCARD_POLL_COUNT
    ),
    refetchInterval: CREDITCARD_POLL_INTERVAL,
  })

  const putCreditCardMutation = useMutation(putCreditCard, {
    onSuccess: response => {
      if (isBrowser()) {
        window.location = response.redirectUri
      }
    },
    onError: () => {
      handleSetError('Mistókst að skrá kort')
    }
  })

  const handleSetError = error => {
    setTransactionId(false)
    setError(error)
  }

  const resetState = () => {
    setError(null)
    setTransactionId(false)
    setPollCount(0)
    setCardRegistered(false)
  }

  const submitCreditCard = creditCardInfo => {
    putCreditCardMutation.mutate({ userId: userId, creditCardInfo: creditCardInfo })
  }

  return userId && !transactionId && !error ? (
      <>
        <Container width='100%' display='flex'>
          <CreditCardForm callback={creditCardInfo => submitCreditCard(creditCardInfo)}/>
        </Container>
      </>
  ) : transactionId && !error ? (
    <>
      <Container width='100%' height='100vh' display='flex'>
        <Container margin='auto' textAlign='center'>
          <Text text='Kortauppfærsla í gangi.' />
          <Text text='Vinsamlegast ekki loka glugganum.' />
          <Spinner />
        </Container>
      </Container>
    </>
  ) : cardRegistered && !error ? (
    <>
      <Container width='100%' display='flex'>
        <Container width='428px' height='80vh' display='flex'>
          <Container margin='auto' textAlign='center'>
            <ErrorTextBig>Kortaskráning heppnaðist</ErrorTextBig>
            <Text text='Farðu til baka eða smelltu á hamborgarann til að velja aðgerð'/>
          </Container>
        </Container>
      </Container>
    </>
  ) : (
    <>
      <Container width='100%' display='flex'>
        <Container width='428px' height='80vh' display='flex'>
          <Container margin='auto' textAlign='center'>
            <ErrorTextBig>{error}</ErrorTextBig>
            { userId
              ? <StyledButton onClick={() => resetState()}>Reyna aftur</StyledButton>
              : <Link to='/'><Text text='Fara til baka á forsíðu'/></Link>
            }
          </Container>
        </Container>
      </Container>
    </>
  )
}

export default PaymentContainer
